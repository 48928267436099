<template>
    <div v-click-outside="close" class="relative z-50">
        <slot :toggle-dropdown="toggleDropdown"></slot>

        <transition
            enter-class="opacity-0 -translate-y-2"
            enter-active-class="transition-all duration-300 ease-out"
            enter-to-classs="opacity-100 translate-y-0"
        >
            <div v-show="active" class="transform">
                <slot name="dropdown"></slot>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'Dropdown',

    data() {
        return {
            active: false,
        };
    },

    methods: {
        toggleDropdown() {
            this.active = !this.active;
        },

        close() {
            if (this.active) {
                this.active = false;
            }
        },
    },
};
</script>
