// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';


import Vue from 'vue/dist/vue.esm.js';
import config from './config.js';

//
// All third-party, non-Vue.js libraries should be loaded here
//

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// Accessible Modal
import MicroModal from 'micromodal';

//
// Setup Vue.js
// Please keep configuration in ./config.js
//

// Apply our config settings, which do most of the grunt work
Vue.use(config);

import {
    AccordionItem,
    CountdownTimer,
    Dropdown,
    Flickity,
    LabeledInput,
    LocationSelect,
    LocationModal,
    Rot13Email,
    StatCounter,
    Tabs,
    Tab,
} from './components';

import { toBoolean } from './utils/bool';

const app = new Vue({
    el: '#app',
    delimiters: ['${', '}'],

    components: {
        AccordionItem,
        CountdownTimer,
        Dropdown,
        Flickity,
        LabeledInput,
        LocationSelect,
        LocationModal,
        Rot13Email,
        StatCounter,
        Tabs,
        Tab,
    },

    data() {
        return {
            navActive: false,
            secondaryNavActive: false,
            userLoggedIn: false,
            isAdmin: false,
            accountInfo: '',
        };
    },

    created() {
        const userLoggedIn = this.$cookies.get('userLoggedIn');
        const accountInfo = this.$cookies.get('accountInfo');
        const isAdmin = this.$cookies.get('isAdmin');

        if (userLoggedIn) {
            this.userLoggedIn = toBoolean(userLoggedIn);
        }

        if (accountInfo) {
            this.accountInfo = accountInfo;
        }

        if (isAdmin) {
            this.isAdmin = toBoolean(isAdmin);
        }

        this.$axios.get('/actions/site-module/base/session').then((response) => {
            this.setAccount(response.data.__identity);
            this.setIsAdmin(response.data.__isAdmin);
        });
    },

    mounted() {
        MicroModal.init();
    },

    methods: {
        toggleNav() {
            this.navActive = !this.navActive;
        },

        toggleSecondaryNav() {
            this.secondaryNavActive = !this.secondaryNavActive;
        },

        setAccount(loggedId) {
            // Just to be safe...
            this.userLoggedIn = toBoolean(loggedId);
            this.$cookies.set('userLoggedIn', toBoolean(loggedId));

            this.accountInfo = loggedId;
            this.$cookies.set('accountInfo', loggedId);
        },

        setIsAdmin(isAdmin) {
            this.isAdmin = toBoolean(isAdmin);
            this.$cookies.set('isAdmin', isAdmin);
        },
    },
});
