<template>
    <form method="POST" accept-charset="UTF-8" novalidate :data-vv-scope="uid" @submit.prevent="validateBeforeSubmit">
        <slot :uid="uid"></slot>

        <slot name="submit" :loading="loading" :success="success" :error="error">
            <button type="submit" :disabled="loading" :class="['btn btn-primary', { 'loading': loading }]">
                {{ buttonText }}
            </button>
        </slot>

        <slot name="messages"></slot>
    </form>
</template>

<script>
import { isVisible } from '../../utils/dom';
import { randomString } from '../../utils/string';

const random = randomString();

export default {
    name: 'PostForm',

    inject: {
        $validator: '$validator',
    },

    props: {
        action: {
            type: String,
            default: '/',
        },

        buttonText: {
            type: String,
            default: 'Submit',
        },
    },

    data() {
        return {
            success: false,
            loading: false,
            error: false,
            errorMessage: '',
        };
    },

    methods: {
        validateBeforeSubmit() {
            this.loading = true;

            // A little hack to only validate visible fields
            const visibleFields = [];

            // Get the fields for just this form (scoped using the uid of this component)
            const scopedFields = this.fields[`$${this.uid}`] || {};

            // Only include visible fields in validation
            Object.keys(scopedFields).forEach((key) => {
                const field = this.$validator.fields.find({ name: key, scope: this.uid });

                if (isVisible(field.el)) {
                    visibleFields.push(key);
                }
            });

            this.$validator.validateAll(this.uid, visibleFields).then((isValid) => {
                if (!isValid) {
                    this.loading = false;

                    return;
                }

                this.$el.submit();
            });
        },

    },
};

</script>
