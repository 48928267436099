<template>
    <div>
        <select id="location" v-model="location" class="form-select p-0 border-none focus:outline-none focus:text-primary-500" name="location">
            <slot></slot>
        </select>
    </div>
</template>

<script>
export default {
    name: 'LocationSelect',

    props: {
        defaultLocation: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            location: this.defaultLocation,
        };
    },

    watch: {
        location(url) {
            window.location.href = url;
        },
    },
};
</script>
