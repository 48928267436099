<template>
    <a :href="'mailto:' + rot13Decode(email)">{{ rot13Decode(email) }}</a>
</template>]

<script>
export default {
    name: 'Rot13Email',

    props: {
        email: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
        };
    },

    methods: {
        rot13Decode(value) {
            value = value.toString();

            return value.replace(/[a-zA-Z]/g, (c) => {
                return String.fromCharCode((c <= 'Z' ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26);
            });
        },
    },
};
</script>
