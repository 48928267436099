<template>
    <div v-show="active" v-cloak>
        <slot :redirect-site-name="redirectSiteName" :redirect-url="redirectUrl" :add-override-cookie="addOverrideCookie"></slot>
    </div>
</template>

<script>
import axios from 'axios';
import MicroModal from 'micromodal';

export default {
    name: 'LocationModal',

    data() {
        return {
            active: false,
            redirectUrl: '',
            redirectSiteName: '',
        };
    },

    mounted() {
        this.getGeolocationInfo();
    },

    methods: {
        setCookie(name, value, hours) {
            let expires = '';

            if (hours) {
                const date = new Date();
                date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
                expires = `; expires=${date.toUTCString()}`;
            }

            document.cookie = `${name}=${value}${expires}; path=/`;
        },

        addOverrideCookie(cookieValue) {
            this.setCookie('GeoMateRedirectOverride', cookieValue, 12);
            MicroModal.close('location-switcher-modal');
            this.active = false;
        },

        getGeolocationInfo() {
            axios.get('/actions/site-module/base/get-geolocation-info').then(({ data }) => {
                if (data && data.site) {
                    this.active = true;

                    this.redirectSiteName = data.site.name;
                    this.redirectUrl = data.site.baseUrl;

                    MicroModal.show('location-switcher-modal');
                }
            });
        },
    },
};
</script>
