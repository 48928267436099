import Vue from 'vue';
import Axios from 'axios';
import { stringify } from 'qs';

import * as Plugins from './plugins';
import './utils/promise';

//
// Create a config object to pass back to Vue.js when setting up for the first time
//

const Config = {
    install(Vue) {
        // Global events can be accessed via `this.$events`
        Vue.prototype.$events = new Vue();

        //
        // Setup Globals
        //

        // Get the CSRF token for form requests from a meta tag in _layout
        const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content || '';

        // Attach Axios instance to Vue, so we can use `this.$axios.get('/')`
        Vue.prototype.$axios = Axios.create({
            headers: {
                'X-CSRF-Token': csrfToken,
            },
            transformRequest: [
                function(data) {
                    // If this is FormData, no need to serialize
                    if (data instanceof FormData) {
                        return data;
                    }

                    return stringify(data);
                },
            ],
        });

        //
        // Setup Plugins
        //

        Object.values(Plugins).forEach((Plugin) => {
            Vue.use(Plugin);
        });
    },
};

export default Config;

// Remove our preload class from the body, preventing early CSS transitions
document.addEventListener('DOMContentLoaded', () => {
    document.getElementsByTagName('body')[0].classList.remove('preload');
});
